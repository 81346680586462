import { ApiService, Authentication } from '../../common'
import { Account, Pagination } from '@one/types'
import { AxiosPromise } from 'axios'

export class AccountAppApi extends ApiService {
  createUser(
    dataUser: Account.App.Requests.CreateUserOption
  ): AxiosPromise<void> {
    this.invalidate(['users'])
    return this.post(`/users`, dataUser, {
      authentication: Authentication.REQUIRED
    })
  }

  updateUser(
    idUser: string,
    dataUser: Account.App.Requests.EditUserOption
  ): AxiosPromise<void> {
    this.invalidate(['users'])
    return this.put(`/users/${idUser}`, dataUser, {
      authentication: Authentication.REQUIRED
    })
  }

  deleteUser(idUser: string): AxiosPromise<void> {
    this.invalidate(['users'])
    return this.delete(`/users/${idUser}`, {
      authentication: Authentication.REQUIRED
    })
  }

  getCurrentUser(): AxiosPromise<Account.App.Responses.User> {
    return this.get('/users/self', {
      authentication: Authentication.REQUIRED
    })
  }

  getUserBasedOnToken(token: string): AxiosPromise<Account.App.Responses.User> {
    return this.get('/users/self', {
      authentication: Authentication.REQUIRED,
      injectToken: false,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  updateCurrentUser(
    dataUser: Account.App.Requests.EditUserOption
  ): AxiosPromise<void> {
    return this.put(`/users/self`, dataUser, {
      authentication: Authentication.REQUIRED
    })
  }

  updateCurrentPassword(
    dataUser: Account.App.Requests.Password
  ): AxiosPromise<void> {
    return this.put(`/users/self/password`, dataUser, {
      authentication: Authentication.REQUIRED
    })
  }

  updateCurrentPasswordWithoutToken(
    dataUser: Account.App.Requests.Password,
    token?: string
  ): AxiosPromise<void> {
    return this.put(`/users/self/password`, dataUser, {
      authentication: Authentication.REQUIRED,
      injectToken: false,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  editIsActiveUser(
    idUser: string,
    dataIsActive: Account.App.Requests.SetIsActiveOption
  ): AxiosPromise<void> {
    this.invalidate(['users'])
    return this.put(`/users/${idUser}/active`, dataIsActive, {
      authentication: Authentication.REQUIRED
    })
  }

  getUsersList(
    data: Account.App.Requests.UsersListPager
  ): AxiosPromise<Pagination.Pager<Account.App.Responses.User>> {
    return this.get(
      `/users/my-organization-users?pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`,
      {
        authentication: Authentication.REQUIRED,
        cache: {
          tags: ['users']
        }
      }
    )
  }

  getAccountRegistrationSetting(): AxiosPromise<
    Account.App.Responses.RegistrationSettings
  > {
    return this.get('/settings/registration', {
      authentication: Authentication.PUBLIC
    })
  }

  getAccountWarehouseSetting(): AxiosPromise<
    Account.App.Responses.WarehouseSettingsDto
  > {
    return this.get('/settings/warehouse', {
      authentication: Authentication.PUBLIC,
      cache: {
        ttl: 60 * 100,
        tags: ['warehouse-setting']
      }
    })
  }

  getAgreementsForCurrentUser(): AxiosPromise<
    Array<Account.App.Responses.Agreement>
  > {
    return this.get('/agreements', {
      authentication: Authentication.REQUIRED,
      cache: {
        ttl: 60 * 100,
        tags: ['agreements']
      }
    })
  }

  getElectronicInvoicesAgreementForCurrentUser(): AxiosPromise<
    Account.App.Responses.InvoiceAgreement
  > {
    return this.get('/agreements/electronic_invoices', {
      authentication: Authentication.REQUIRED
    })
  }

  getListAgreements(
    clientType: string
  ): AxiosPromise<Array<Account.App.Responses.Agreement>> {
    return this.get('/agreements', {
      params: {
        clientType
      },
      authentication: Authentication.PUBLIC
    })
  }

  getElectronicInvoicesAgreement(): AxiosPromise<
    Account.App.Responses.InvoiceAgreement
  > {
    return this.get('/agreements/electronic_invoices', {
      authentication: Authentication.PUBLIC
    })
  }

  updateAgreement(
    agreementId: string,
    data: Account.App.Requests.EditAgreement
  ): AxiosPromise<void> {
    this.invalidate(['agreements'])
    return this.put(`/agreements/${agreementId}`, data, {
      authentication: Authentication.REQUIRED
    })
  }

  updateAgreementWithoutToken(
    agreementId: string,
    data: Account.App.Requests.EditAgreement,
    token?: string
  ): AxiosPromise<void> {
    this.invalidate(['agreements'])
    return this.put(`/agreements/${agreementId}`, data, {
      authentication: Authentication.REQUIRED,
      injectToken: false,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  updateElectronicInvoicesAgreement(
    data: Account.App.Requests.EditInvoiceAgreement
  ): AxiosPromise<void> {
    return this.put('/agreements/electronic_invoices', data, {
      authentication: Authentication.REQUIRED
    })
  }

  createOptionalAgreement(
    agreementId: string,
    agreement: Account.App.Requests.AddOrDeleteAgreementForUnregisteredUserDto
  ): AxiosPromise<void> {
    return this.post(`agreements/${agreementId}`, agreement, {
      authentication: Authentication.OPTIONAL
    })
  }

  deleteOptionalAgreement(
    agreementId: string,
    agreement: Account.App.Requests.AddOrDeleteAgreementForUnregisteredUserDto
  ): AxiosPromise<void> {
    return this.delete(`agreements/${agreementId}`, {
      data: agreement,
      authentication: Authentication.OPTIONAL
    })
  }

  getClientAddresses(
    data: Account.App.Requests.AddressesPager
  ): AxiosPromise<Pagination.Pager<Account.App.Responses.Address>> {
    return this.get(
      `/addresses/my-organization-addresses?rows=${data.rows}&start=${data.start}`,
      {
        authentication: Authentication.REQUIRED,
        cache: {
          tags: ['client', 'address']
        }
      }
    )
  }

  createAddress(address: Account.App.Requests.Address): AxiosPromise<string> {
    this.invalidate(['address'])
    return this.post(`/addresses`, address, {
      authentication: Authentication.REQUIRED
    })
  }

  deleteAddress(id: string): AxiosPromise<void> {
    this.invalidate(['address'])
    return this.delete(`/addresses/${id}`, {
      authentication: Authentication.REQUIRED
    })
  }

  setDefaultAddress(id: string): AxiosPromise<void> {
    this.invalidate(['address'])
    return this.post(
      `/addresses/${id}/default-shipping`,
      {},
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  getClient(): AxiosPromise<Account.App.Responses.ClientRestView> {
    return this.get('/clients/self', {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['client']
      }
    })
  }

  createOrganization(
    data: Account.App.Requests.CreateOrganization
  ): AxiosPromise<void> {
    return this.post('/organizations', data, {
      authentication: Authentication.PUBLIC
    })
  }

  createIndividualUser(
    data: Account.App.Requests.CreateIndividualClient
  ): AxiosPromise<void> {
    return this.post('/individual-clients', data, {
      authentication: Authentication.PUBLIC
    })
  }

  getUsersWithPickupPermission(): AxiosPromise<
    Array<Account.App.Responses.UserQueryDTO>
  > {
    return this.get('/users/authorized-to-receive-delivery', {
      authentication: Authentication.REQUIRED
    })
  }

  getUsersWithAuthorizationPermission(): AxiosPromise<
    Array<Account.App.Responses.UserQueryDTO>
  > {
    return this.get('/users/authorized-to-authorize-carts', {
      authentication: Authentication.REQUIRED
    })
  }

  getTradeCreditByClient(): AxiosPromise<Account.App.Responses.TradeCredit> {
    return this.get(`/trade-credits/my-organization-trade-credit`, {
      authentication: Authentication.REQUIRED
    })
  }

  getTradeCreditForPayer(): AxiosPromise<Account.App.Responses.TradeCredit> {
    return this.get('/trade-credits/my-payer-trade-credit', {
      authentication: Authentication.REQUIRED
    })
  }

  getTaxpayerAddress(
    tin: string
  ): AxiosPromise<Account.App.Responses.BillingAddress> {
    return this.get(`/taxpayer/address?tin=${tin}`, {
      authentication: Authentication.PUBLIC
    })
  }

  getAdditionalFieldsVisible(
    viewName: string
  ): AxiosPromise<Account.App.Responses.AdditionalFields> {
    return this.get(
      `/configurations/additional-fields/visible?viewName=${viewName}`,
      {
        authentication: Authentication.PUBLIC
      }
    )
  }
}
