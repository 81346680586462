import { AxiosPromise } from 'axios'
import { ApiService, Audience, Authentication } from '../../common'

export class AppPaymentMethodsApi extends ApiService {
  getPaymentStatus(
    orderId: string
  ): AxiosPromise<{ status: any; paymentUrl: string; clientSecret?: string }> {
    return this.get(`/payments/status/${orderId}`, {
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getStripePublishableKey(paymentMethodId: string): AxiosPromise<string> {
    return this.get(
      `/payment-methods/${paymentMethodId}/stripe/publishable-key`,
      {
        authentication: Authentication.OPTIONAL,
        aud: Audience.APP
      }
    )
  }
}
